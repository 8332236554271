<template>
  <a-form-model
    style="background: #ffffff;min-height:900px;padding-top: 20px;"
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item ref="name" label="角色名称：" prop="name">
      <a-input
        @blur="
          () => {
            $refs.name.onFieldBlur();
          }
        "
        placeholder="请输入角色名称"
        v-model="form.name"
      />
    </a-form-model-item>
    <a-form-model-item ref="describe" label="角色描述：" prop="describe">
      <a-input
        @blur="
          () => {
            $refs.describe.onFieldBlur();
          }
        "
        placeholder="请输入角色描述"
        v-model="form.describe"
      />
    </a-form-model-item>
    <!-- <a-form-model-item label="角色级别：" prop="resource">
      <a-radio-group v-model="form.resource">
        <a-radio value="0">
          省级
        </a-radio>
        <a-radio value="1">
          市级
        </a-radio>
        <a-radio value="2">
          县级
        </a-radio>
      </a-radio-group>
    </a-form-model-item> -->
    <a-form-model-item label="所属组织" prop="org">
      <a-select
        v-model="form.deptIds"
        placeholder="请选择所属组织"
        @change="handleChange"
        :disabled="show == 1 ? true : false"
      >
        <a-select-option
          :value="item.dbId"
          v-for="(item, index) in deptList"
          :key="index"
        >
          {{ item.dept_name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      ref="role"
      label="角色权限："
      prop="role"
      :wrapper-col="wrapperCol12"
    >
      <div class="role-auth">
        <div class="role-authone">
          <div class="role-authone-title">
            <div>
              <a-checkbox
                @change="onCheckAllChange"
                class="checkbox"
                v-model="checkedOneAll"
              >
                {{ count_checked1 }}/{{ count1 }}
              </a-checkbox>
            </div>
            <div>
              模块
            </div>
          </div>
          <div class="role-authone-con">
            <div
              :class="oneIndex == index ? 'select' : ''"
              v-for="(item, index) in menuList"
              :key="index"
              @click="selectOne(index)"
            >
              <a-checkbox
                @change="checked => onChange(checked, item.id)"
                v-model="item.checked"
                :disabled="show == 1"
              ></a-checkbox
              ><span style="margin-left:10px;cursor: pointer;">{{
                item.name
              }}</span>
            </div>
          </div>
        </div>
        <div class="role-authone">
          <div class="role-authone-title">
            <div>
              <a-checkbox @change="onCheckAllTwoChange" v-model="checkedTwoAll">
                {{ count_checked2 }}/{{ count2 }} 项
              </a-checkbox>
            </div>
            <div>
              二级模块
            </div>
          </div>
          <div class="role-authone-con">
            <div
              :class="twoIndex == index ? 'select' : ''"
              v-for="(item, index) in menuTwoList"
              :key="index"
              @click="selectTwo(index)"
            >
              <a-checkbox
                @change="
                  checked => onChangeTwo(checked, item.id, item.parent_id)
                "
                v-model="item.checked"
                :disabled="show == 1"
              ></a-checkbox
              ><span style="margin-left:10px;cursor: pointer;">
                {{ item.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="role-authone">
          <div class="role-authone-title">
            <div>
              <a-checkbox
                @change="onCheckAllThreeChange"
                v-model="checkedThreeAll"
              >
                {{ count_checked3 }}/{{ count3 }} 项
              </a-checkbox>
            </div>
            <div>
              三级模块
            </div>
          </div>
          <div class="role-authone-con">
            <div
              :class="threeIndex == index ? 'select' : ''"
              v-for="(item, index) in menuThreeList"
              :key="index"
              @click="selectThree(index)"
            >
              <a-checkbox
                @change="
                  checked => onChangeThree(checked, item.id, item.parent_id)
                "
                v-model="item.checked"
                :disabled="show == 1"
              ></a-checkbox
              ><span style="margin-left:10px;cursor: pointer;">{{
                item.name
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </a-form-model-item>
    <a-form-model-item
      ref="permission"
      label="数据权限："
      prop="role"
      :wrapper-col="wrapperCol12"
    >
      <div class="role-auth">
        <div class="role-authone">
          <div class="role-authone-title">
            <div>
              <a-checkbox
                @change="onCheckAllPermissionOneChange"
                v-model="checkedPermissionOneAll"
                class="checkbox"
              >
                {{ data_permission_checked_count1 }}/{{
                  data_permission_count1
                }}
              </a-checkbox>
            </div>
            <div>
              模块
            </div>
          </div>
          <div class="role-authone-con">
            <div
              :class="permissionIndex == index ? 'select' : ''"
              v-for="(item, index) in permissionListOne"
              :key="index"
              @click="selectPermission(index)"
            >
              <a-checkbox
                @change="checked => onChangePermission(checked, index, item, 1)"
                v-model="item.checked"
                :disabled="show == 1"
              ></a-checkbox
              ><span style="margin-left:10px;cursor: pointer;">{{
                item.name
              }}</span>
            </div>
          </div>
        </div>
        <div class="role-authone">
          <div class="role-authone-title">
            <div>
              <a-checkbox
                @change="onCheckAllPermissionTwoChange"
                v-model="checkedPermissionTwoAll"
              >
                {{ data_permission_checked_count2 }}/{{
                  data_permission_count2
                }}
                项
              </a-checkbox>
            </div>
            <div>
              二级模块
            </div>
          </div>
          <div class="role-authone-con">
            <div v-for="(item, index) in permissionListTwo" :key="index">
              <a-checkbox
                @change="checked => onChangePermission(checked, index, item, 2)"
                v-model="item.checked"
                :disabled="show == 1"
              ></a-checkbox
              ><span style="margin-left:10px;cursor: pointer;">{{
                item.name
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </a-form-model-item>
    <!--<a-form-model-item ref="role" label="角色权限：" prop="role" :wrapper-col="wrapperCol12">
      <span>集中供水、</span>
      <span>水质检测中心、</span>
      <span>三项责任 三项制度</span>
    </a-form-model-item>-->
    <a-form-model-item
      :wrapper-col="{ span: 8, offset: 8 }"
      style="text-align: center;"
    >
      <a-button type="primary" @click="onSubmit" v-if="!id && !show">
        立即创建
      </a-button>
      <a-button type="primary" @click="onSubmit" v-if="id && !show">
        立即修改
      </a-button>
      <a-button style="margin-left: 10px;" @click="resetForm">
        取消
      </a-button>
      <!--      <a-button style="margin-left: 10px;" @click="resetForm">-->
      <!--        返回-->
      <!--      </a-button>-->
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { getMenuList, getRoleMenuList } from "@/api/permission/menu/index";
import { createRole, showRole, editRole } from "@/api/permission/role/index";

import { getDeptList } from "@/api/permission/organization/index";

export default {
  data() {
    return {
      id: 0,
      show: 0,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      wrapperCol12: { span: 12 },
      form: {
        name: "",
        describe: ""
        // resource: ""
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        describe: [
          { required: false, message: "请输入组织描述", trigger: "blur" }
        ]
        // resource: [
        //   {
        //     required: true,
        //     message: "请选择角色级别",
        //     trigger: "change"
        //   }
        // ]
      },
      deptList: [],

      // 模块
      menuList: [], // 菜单树
      menuTwoList: [], // 二级菜单
      menuThreeList: [], // 三级菜单
      oneIndex: -1, //一级菜单index,
      twoIndex: -1, //二级菜单index,
      threeIndex: -1, //三级菜单index,

      check_ids: [], // 选中的菜单id
      menuIds: [],

      count1: 0,
      count_checked1: 0,
      count2: 0,
      count_checked2: 0,
      count3: 0,
      count_checked3: 0,
      checkedOneAll: false,
      checkedTwoAll: false,
      checkedThreeAll: false,

      // 数据权限
      dataPermissions: [
        {
          name: "四率（五率）",
          column: "rate",
          checked: false,
          children: [
            {
              name: "集中供水率",
              column: "focusWasePow",
              checked: false
            },
            {
              name: "自来水普及率",
              column: "wawPow",
              checked: false
            },
            {
              name: "规模化供水率",
              column: "scalePow",
              checked: false
            },
            {
              name: "供水保证率",
              column: "endPow",
              checked: false
            },
            {
              name: "水质达标率",
              column: "qualityPow",
              checked: false
            }
          ]
        },
        {
          name: "集中供水工程详表",
          column: "focus",
          checked: false,
          children: [
            {
              name: "序号",
              column: "key",
              checked: false,
              width: 80
            },
            {
              name: "上报年度",
              column: "reportYear",
              checked: false,
              width: 80
            },
            {
              name: "供水工程名称",
              column: "cwsName",
              checked: false,
              width: 200
            },
            {
              name: "省",
              column: "province",
              checked: false,
              width: 80
            },
            {
              name: "市",
              column: "city",
              checked: false,
              width: 80
            },
            {
              name: "县",
              column: "country",
              checked: false,
              width: 80
            },
            {
              name: "所在位置",
              column: "location",
              checked: false,
              width: 310,
              children: [
                {
                  name: "乡镇、村庄 (或农场、林 场、连队)名 称",
                  column: "cwsLoc",
                  width: 300
                },
                {
                  name: `东经(°´")`,
                  column: "waterLongitu",
                  width: 80
                },
                {
                  name: `北纬(°´")`,
                  column: "waterLatitude",
                  width: 80
                }
              ]
            },
            {
              name: "建设情况",
              column: "build",
              checked: false,
              width: 320,
              children: [
                {
                  name: "工程总投资（万元）",
                  column: "totalInvest",
                  width: 80
                },
                {
                  name: `中央投资 (万元)`,
                  column: "midInvest",
                  width: 80
                },
                {
                  name: `开始供水时间（年月）`,
                  column: "yearWatter",
                  width: 80
                },
                {
                  name: `竣工验收（是／否）`,
                  column: "compCheck",
                  width: 80
                }
              ]
            },
            {
              name: "工程性质",
              column: "engine",
              checked: false,
              width: 160,
              children: [
                {
                  name: "非打包工程",
                  column: "isMoreHundred",
                  width: 80
                },
                {
                  name: `打包工程处数（处）`,
                  column: "isMoreHundredCount",
                  width: 80
                }
              ]
            },
            {
              name: "设计供水能力(m3/d)",
              column: "desWasuScal",
              checked: false,
              width: 80
            },
            {
              name: "年实际供水量(m3)",
              column: "yearWatter",
              checked: false,
              width: 80
            },
            {
              name: "供水情况",
              column: "situation",
              checked: false,
              width: 560,
              children: [
                {
                  name: "实际供水 人数（人）",
                  column: "desWasuPop",
                  width: 80
                },
                {
                  name: `设计供水 人数（人）`,
                  column: "waterPer",
                  width: 80
                },
                {
                  name: "其中供水 到户人数 （人）",
                  column: "waterPerH",
                  width: 80
                },
                {
                  name: "其中受益 学校师生 人数（人）",
                  column: "teachStudNum",
                  width: 80
                },
                {
                  name: "其中覆盖 建档立卡 贫困户数 （户））",
                  column: "poorHome",
                  width: 80
                },
                {
                  name: "其中覆盖 建档立卡 贫困人数 （人）",
                  column: "poorPop",
                  width: 80
                },
                {
                  name: "其中2018年以来 解决的饮水型 氟超标人数 （人）",
                  column: "fluoOverPeoplenum",
                  width: 80
                }
              ]
            },
            {
              name: "水源",
              column: "water",
              checked: false,
              width: 400,
              children: [
                {
                  name: "水源类型",
                  column: "waterType",
                  width: 80
                },
                {
                  name: `地表水源 名称`,
                  column: "surWaterName",
                  width: 80
                },
                {
                  name: "水源水质 状况",
                  column: "waterQualityState",
                  width: 80
                },
                {
                  name: "是否建立水源 保护区或划定 水源保护范围",
                  column: "buildProArea",
                  width: 80
                },
                {
                  name: "是否有取水许可证",
                  column: "getWaterCard",
                  width: 80
                }
              ]
            },
            {
              name: "是否有卫生许可证",
              column: "sanitationCard",
              checked: false,
              width: 80
            },
            {
              name: "供水保证率",
              column: "waterSupRate",
              checked: false,
              width: 80
            },
            {
              name: "管网漏损率",
              column: "leakageRate",
              checked: false,
              width: 80
            },
            {
              name: "净化方式",
              column: "purify",
              checked: false,
              width: 160,
              children: [
                {
                  name: "方式",
                  column: "purWay",
                  width: 80
                },
                {
                  name: "运行状况",
                  column: "purCondition",
                  width: 80
                }
              ]
            },
            {
              name: "消毒方式",
              column: "disinfect",
              checked: false,
              width: 160,
              children: [
                {
                  name: "方式",
                  column: "disinWay",
                  width: 80
                },
                {
                  name: `运行状况`,
                  column: "disinCondition",
                  width: 80
                }
              ]
            },
            {
              name: "水质化验室",
              column: "laboratory",
              checked: false,
              width: 160,
              children: [
                {
                  name: "是否建立",
                  column: "buildTestCabinet",
                  width: 80
                },
                {
                  name: `运行状况`,
                  column: "operCondition",
                  width: 80
                }
              ]
            },
            {
              name: "计算机监控系统",
              column: "computer",
              checked: false,
              width: 160,
              children: [
                {
                  name: "有/无",
                  column: "compuMoni",
                  width: 80
                },
                {
                  name: `运行状况`,
                  column: "moniOperCondition",
                  width: 80
                }
              ]
            },
            {
              name: "视频安防系统",
              column: "video",
              checked: false,
              width: 160,
              children: [
                {
                  name: "有/无",
                  column: "viewSys",
                  width: 80
                },
                {
                  name: `运行状况`,
                  column: "viewSysCondition",
                  width: 80
                }
              ]
            },
            {
              name: "水费水价",
              column: "cost",
              checked: false,
              width: 400,
              children: [
                {
                  name: "全成本水价（元/m³）",
                  column: "allCost",
                  width: 80
                },
                {
                  name: `计费方式`,
                  column: "billingWay",
                  width: 80
                },
                {
                  name: "居民生活执行水价（元/m³）",
                  column: "resLiveCost",
                  width: 80
                },
                {
                  name: "水费回收率（%)",
                  column: "waterRateRecovery",
                  width: 80
                },
                {
                  name: "收支情况",
                  column: "inOutCondition",
                  width: 80
                }
              ]
            },
            {
              name: "运行管理",
              column: "manage",
              checked: false,
              width: 400,
              children: [
                {
                  name: "工程运行管理单位名称",
                  column: "proInstiName",
                  width: 150
                },
                {
                  name: `工程专职运行管理人员数量（人）`,
                  column: "proPerNum",
                  width: 80
                },
                {
                  name: "工程上级主管单位名称",
                  column: "higherInstiName",
                  width: 80
                },
                {
                  name: "工程运行管理单位性质",
                  column: "natManageUnit",
                  width: 80
                }
              ]
            },
            {
              name: "供水单位负责人",
              column: "contact",
              checked: false,
              width: 160,
              children: [
                {
                  name: "姓名",
                  column: "waterRespName",
                  width: 80
                },
                {
                  name: `联系电话`,
                  column: "waterServicePhone",
                  width: 80
                }
              ]
            },
            {
              name: "供水单位服务热线",
              column: "contactWay",
              checked: false,
              width: 100
            }
          ]
        },
        {
          name: "数据填报",
          column: "report",
          checked: false,
          children: [
            {
              name: "报告编辑",
              column: "report_edit",
              checked: false
            }
          ]
        }
      ],
      permissionIndex: -1,
      permissionListOne: [],
      data_permission_count1: 0,
      data_permission_checked_count1: 0,
      permissionListTwo: [],
      data_permission_count2: 0,
      data_permission_checked_count2: 0,
      checkedPermissionOneAll: false,
      checkedPermissionTwoAll: false
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getRoleMenus();
      showRole({
        roleId: this.id
      }).then(res => {
        console.log(res);
        // console.log(res.data.menuIds);
        if (res.code == 200) {
          this.form.name = res.data.role_name;
          this.form.describe = res.data.remark;
          let permission = JSON.parse(res.data.permission);
          this.getRolePermissions(permission);
          // this.menuIds = res.data.menus;
        }
      });
    } else {
      this.getMenus();
      this.getPermissionList();
    }
    if (this.$route.query.show) {
      this.show = this.$route.query.show;
    }
    this.getOrganizations();
  },
  methods: {
    getOrganizations() {
      this.deptList = [];
      getDeptList({}).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.deptList = res.data;
        }
      });
    },
    handleChange(value) {
      this.form.deptIds = value;
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.id) {
            this.updateRole();
          } else {
            this.addRole();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$router.push({
        path: "/index/auth/role/roleList"
      });
    },
    // 获取菜单树结构
    getMenus() {
      this.menuList = [];
      this.count1 = 0;
      getMenuList().then(res => {
        if (res.code == 200) {
          let checkedOneIndex = 0;
          let checkedTwoIndex = 0;
          let checkedThreeIndex = 0;
          for (let i in res.data) {
            let one = res.data[i];
            let checkedOne =
              this.check_ids.indexOf(one.dbId) > -1 ? true : false;
            // 一级菜单
            let menuOne = {
              id: one.dbId,
              name: one.menu_name,
              parent_id: one.parent_id,
              children: one.children,
              checked: checkedOne
            };
            if (checkedOne) {
              this.count_checked1++;
              checkedOneIndex = i;
            }
            for (let j in one.children) {
              let two = one.children[j];
              let checkedTwo =
                this.check_ids.indexOf(two.dbId) > -1 ? true : false;
              // 二级菜单
              let menuTwo = {
                id: two.dbId,
                name: two.menu_name,
                parent_id: two.parent_id,
                children: two.children,
                checked: checkedTwo
              };
              if (checkedTwo) {
                checkedTwoIndex = j;
              }
              for (let k in two.children) {
                let three = two.children[k];
                let checkedThree =
                  this.check_ids.indexOf(three.dbId) > -1 ? true : false;
                // 三级菜单
                let menuThree = {
                  id: three.dbId,
                  name: three.menu_name,
                  parent_id: three.parent_id,
                  checked: checkedThree
                };
                if (checkedThree) {
                  checkedThreeIndex = k;
                }
                menuTwo.children[k] = menuThree;
              }
              menuOne.children[j] = menuTwo;
            }
            this.menuList.push(menuOne);
            this.count1++;
          }
          // 编辑状态时判断选中
          if (checkedOneIndex) {
            this.oneIndex = checkedOneIndex;
            // 赋值当前选择的一级菜单下的二级菜单
            this.menuTwoList = this.menuList[checkedOneIndex].children;
            this.count2 = this.menuTwoList.length;
            for (let i in this.menuTwoList) {
              if (this.check_ids.indexOf(this.menuTwoList[i].id) > -1) {
                this.count_checked2++;
              }
            }
            if (checkedTwoIndex) {
              this.twoIndex = checkedTwoIndex;
              // 赋值当前选择的二级菜单下的三级菜单
              this.menuThreeList = this.menuTwoList[checkedTwoIndex].children;
              this.count3 = this.menuThreeList.length;
              for (let i in this.menuThreeList) {
                if (this.check_ids.indexOf(this.menuThreeList[i].id) > -1) {
                  this.count_checked3++;
                }
              }
            }
            if (checkedThreeIndex) {
              this.threeIndex = checkedThreeIndex;
            }
          }
          console.log("菜单树结构");
          console.log(this.menuList);
        }
      });
    },
    getPermissionList() {
      for (let i in this.dataPermissions) {
        this.data_permission_count1++;
        let data = this.dataPermissions[i];
        this.permissionListOne.push({
          name: data.name,
          column: data.column,
          children: data.children || []
        });
      }
    },
    getRoleMenus() {
      getRoleMenuList({
        roleId: this.id
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.menuIds = res.checkedKeys;
          this.check_ids = res.checkedKeys;
          console.log("当前选中的菜单id");
          console.log(this.check_ids);
          for (let i in res.menus) {
            let one = res.menus[i];
            for (let k in one.children) {
              let two = one.children[k];
              // 如果子类选中， 默认其父类也选中
              if (
                this.check_ids.indexOf(two.id) > 0 &&
                this.check_ids.indexOf(one.id) == -1
              ) {
                this.check_ids.push(one.id);
              }
              for (let m in two.children) {
                let three = two.children[m];
                // 如果子类选中， 默认其父类也选中
                if (
                  this.check_ids.indexOf(three.id) > 0 &&
                  this.check_ids.indexOf(two.id) == -1
                ) {
                  this.check_ids.push(two.id);
                }
                if (
                  this.check_ids.indexOf(three.id) > 0 &&
                  this.check_ids.indexOf(one.id) == -1
                ) {
                  this.check_ids.push(one.id);
                }
              }
            }
          }
        }
        this.getMenus();
      });
    },
    getRolePermissions(permission) {
      console.log(permission);
      if (permission) {
        for (let i in this.dataPermissions) {
          this.data_permission_count1++;
          let data = this.dataPermissions[i];
          let result = permission[i];
          this.dataPermissions[i].checked = result.checked;
          if (result.checked) {
            this.data_permission_checked_count1++;
            for (let j in data.children) {
              this.data_permission_count2++;
              let two = data.children[j];
              let two_checked = result.children[j].checked;
              this.dataPermissions[i].children[j].checked = two_checked;
              if (two_checked) {
                this.data_permission_checked_count2++;
              }
              this.permissionListTwo.push({
                name: two.name,
                column: two.column,
                children: two.children || [],
                parent_index: i,
                ori_index: j,
                checked: two_checked
              });
            }
          }
          this.permissionListOne.push({
            name: data.name,
            column: data.column,
            children: data.children || [],
            checked: result.checked
          });
        }
      } else {
        this.getPermissionList();
      }
    },
    //选中一级模块样式
    selectOne(index) {
      this.oneIndex = index;
      // 赋值当前选择的一级菜单下的二级菜单
      this.menuTwoList = this.menuList[index].children;
      this.count2 = this.menuTwoList.length;
      this.twoIndex = -1;
      this.count_checked2 = 0;
      this.checkedTwoAll = false;
      for (let i in this.menuTwoList) {
        if (this.check_ids.indexOf(this.menuTwoList[i].id) > -1) {
          this.count_checked2++;
        }
      }
      // 三级菜单初始化
      this.menuThreeList = [];
      this.count3 = 0;
      this.threeIndex = -1;
      this.checkedThreeAll = false;
      console.log("当前选择的一级菜单下二级菜单树");
      console.log(this.menuTwoList);
    },
    //选中二级模块样式
    selectTwo(index) {
      this.twoIndex = index;
      // 赋值当前选择的二级菜单下的三级菜单
      this.menuThreeList = this.menuTwoList[index].children;
      this.count3 = this.menuThreeList.length;
      this.count_checked3 = 0;
      this.checkedThreeAll = false;
      for (let i in this.menuThreeList) {
        if (this.check_ids.indexOf(this.menuThreeList[i].id) > -1) {
          this.count_checked3++;
        }
      }
      console.log("当前选择的二级菜单下三级菜单树");
      console.log(this.menuThreeList);
    },
    //选中三级模块样式
    selectThree(index) {
      this.threeIndex = index;
    },
    // 一级菜单选择
    onChange(e, id) {
      let checked = e.target.checked;
      // 选中
      if (checked) {
        if (this.check_ids.indexOf(id) == -1) {
          this.check_ids.push(id);
          this.count_checked1++;
        }
      } else {
        if (this.check_ids.indexOf(id) > -1) {
          this.check_ids.splice(this.check_ids.indexOf(id), 1);
          this.count_checked1--;
          if (this.count_checked1 < 0) {
            this.count_checked1 = 0;
          }
        }
      }
      console.log("当前选中的菜单id");
      console.log(this.check_ids);
    },
    // 二级菜单选择
    onChangeTwo(e, id, parent_id) {
      let checked = e.target.checked;
      if (checked === true) {
        if (this.check_ids.indexOf(id) == -1) {
          this.check_ids.push(id);
          this.count_checked2++;
        }
        // 父类选中
        if (this.check_ids.indexOf(parent_id) == -1) {
          this.check_ids.push(parent_id);
          for (let i in this.menuList) {
            if (this.menuList[i].id == parent_id) {
              this.menuList[i].checked = true;
              this.count_checked1++;
            }
          }
        }
      } else {
        if (this.check_ids.indexOf(id) > -1) {
          this.check_ids.splice(this.check_ids.indexOf(id), 1);
          this.count_checked2--;
          if (this.count_checked2 < 0) {
            this.count_checked2 = 0;
          }
        }
      }
      console.log("当前选中的菜单id");
      console.log(this.check_ids);
    },
    // 二级菜单选择
    onChangeThree(e, id, parent_id) {
      let checked = e.target.checked;
      if (checked === true) {
        if (this.check_ids.indexOf(id) == -1) {
          this.check_ids.push(id);
          this.count_checked3++;
        }
        // 父类选中
        if (this.check_ids.indexOf(parent_id) == -1) {
          this.check_ids.push(parent_id);
          for (let i in this.menuTwoList) {
            if (this.menuTwoList[i].id == parent_id) {
              this.menuTwoList[i].checked = true;
              this.count_checked2++;
            }
          }
        }
      } else {
        if (this.check_ids.indexOf(id) > -1) {
          this.check_ids.splice(this.check_ids.indexOf(id), 1);
          this.count_checked3--;
          if (this.count_checked3 < 0) {
            this.count_checked3 = 0;
          }
        }
      }
      console.log("当前选中的菜单id");
      console.log(this.check_ids);
    },
    onCheckAllChange(e) {
      let checked = e.target.checked;
      if (checked) {
        this.count_checked1 = this.count1;
        for (let i in this.menuList) {
          this.menuList[i].checked = true;
          if (this.check_ids.indexOf(this.menuList[i].id) == -1) {
            this.check_ids.push(this.menuList[i].id);
          }
        }
      } else {
        this.count_checked1 = 0;
        for (let i in this.menuList) {
          this.menuList[i].checked = false;
          if (this.check_ids.indexOf(this.menuList[i].id) > -1) {
            this.check_ids.splice(
              this.check_ids.indexOf(this.menuList[i].id),
              1
            );
          }
        }
      }
      console.log("当前选中的菜单id");
      console.log(this.check_ids);
    },
    onCheckAllTwoChange(e) {
      let checked = e.target.checked;
      if (checked) {
        this.count_checked2 = this.count2;
        for (let i in this.menuTwoList) {
          this.menuTwoList[i].checked = true;
          if (this.check_ids.indexOf(this.menuTwoList[i].id) == -1) {
            this.check_ids.push(this.menuTwoList[i].id);
          }
        }
      } else {
        this.count_checked2 = 0;
        for (let i in this.menuTwoList) {
          this.menuTwoList[i].checked = false;
          if (this.check_ids.indexOf(this.menuTwoList[i].id) > -1) {
            this.check_ids.splice(
              this.check_ids.indexOf(this.menuTwoList[i].id),
              1
            );
          }
        }
      }
      console.log("当前选中的菜单id");
      console.log(this.check_ids);
    },
    onCheckAllThreeChange(e) {
      let checked = e.target.checked;
      if (checked) {
        this.count_checked3 = this.count3;
        for (let i in this.menuThreeList) {
          this.menuThreeList[i].checked = true;
          if (this.check_ids.indexOf(this.menuThreeList[i].id) == -1) {
            this.check_ids.push(this.menuThreeList[i].id);
          }
        }
      } else {
        this.count_checked3 = 0;
        for (let i in this.menuThreeList) {
          this.menuThreeList[i].checked = false;
          if (this.check_ids.indexOf(this.menuThreeList[i].id) > -1) {
            this.check_ids.splice(
              this.check_ids.indexOf(this.menuThreeList[i].id),
              1
            );
          }
        }
      }
      console.log("当前选中的菜单id");
      console.log(this.check_ids);
    },
    onChangePermission(e, index, item, level) {
      let checked = false;
      if (typeof e == "boolean") {
        checked = e;
      } else {
        checked = e.target.checked;
      }
      switch (level) {
        case 1:
          let children = this.permissionListOne[index].children;
          this.dataPermissions[index].checked = checked;
          if (checked) {
            this.data_permission_checked_count1++;
            if (
              this.data_permission_checked_count1 > this.data_permission_count1
            ) {
              this.data_permission_checked_count1 = this.data_permission_count1;
            }
          } else {
            this.data_permission_checked_count1--;
            if (this.data_permission_checked_count1 < 0) {
              this.data_permission_checked_count1 = 0;
            }
          }

          for (let i in children) {
            let data = children[i];
            let twoList = [];
            for (let m in this.permissionListTwo) {
              twoList.push(this.permissionListTwo[m].column);
            }
            if (checked) {
              if (twoList.indexOf(data.column) === -1) {
                this.data_permission_count2++;
                this.permissionListTwo.push({
                  name: data.name,
                  column: data.column,
                  children: data.children || [],
                  parent_index: index,
                  ori_index: i,
                  checked: false
                });
              }
            } else {
              // 如果是未选中将值删除
              this.permissionListTwo.forEach((item, index2) => {
                if (item.column === data.column) {
                  this.permissionListTwo.splice(index2, 1);
                  this.data_permission_count2--;
                  if (item.checked) {
                    this.data_permission_checked_count2--;
                    if (this.data_permission_checked_count2 < 0) {
                      this.data_permission_checked_count2 = 0;
                    }
                    item.checked = false;
                    this.dataPermissions[index].children[i].checked = false;
                  }
                }
              });
            }
          }
          break;
        case 2:
          let data = this.permissionListTwo[index];
          console.log(data);
          this.dataPermissions[data.parent_index].children[
            item.ori_index
          ].checked = checked;
          if (checked) {
            this.data_permission_checked_count2++;
          } else {
            this.data_permission_checked_count2--;
          }
          break;
      }
      console.log(this.dataPermissions);
    },
    onCheckAllPermissionOneChange(e) {
      let checked = e.target.checked;
      if (checked) {
        for (let i in this.permissionListOne) {
          this.permissionListOne[i].checked = true;
          this.onChangePermission(true, i, this.permissionListOne[i], 1);
        }
      } else {
        this.checekdPermissionTwoAll = false;
        for (let i in this.permissionListOne) {
          this.permissionListOne[i].checked = false;
          this.onChangePermission(false, i, this.permissionListOne[i], 1);
        }
      }
    },
    onCheckAllPermissionTwoChange(e) {
      let checked = e.target.checked;
      if (checked) {
        for (let i in this.permissionListTwo) {
          this.permissionListTwo[i].checked = true;
          this.onChangePermission(true, i, this.permissionListTwo[i], 2);
        }
      } else {
        for (let i in this.permissionListTwo) {
          this.permissionListTwo[i].checked = false;
          this.onChangePermission(false, i, this.permissionListTwo[i], 2);
        }
      }
    },
    selectPermission(index) {
      this.permissionIndex = index;
    },
    checkPermissionIsSelected() {
      let canSubmit = true;
      for (let i in this.dataPermissions) {
        let permission = this.dataPermissions[i];
        // 四率（五率）
        if (i == 0) {
          let count = 0;
          for (let j in permission.children) {
            let result = permission.children[j];
            if (result.checked) {
              count++;
            }
          }

          if (count < 2) {
            this.$message.error("四率（五率）权限至少要勾选2率以上");
            canSubmit = false;
            break;
          }
        }

        // 集中供水详表
        if (i == 1) {
          let count = 0;
          for (let j in permission.children) {
            let result = permission.children[j];
            if (result.checked) {
              count++;
            }
          }

          if (count == 0) {
            this.$message.error("请选择集中供水工程详表的权限");
            canSubmit = false;
            break;
          }
        }
      }
      return canSubmit;
    },
    addRole() {
      let _this = this;
      if (this.checkPermissionIsSelected()) {
        createRole({
          role_name: _this.form.name,
          menuIds: JSON.parse(JSON.stringify(_this.check_ids)),
          remark: _this.form.describe,
          permission: JSON.stringify(this.dataPermissions),
          deptId: _this.form.deptIds
        }).then(res => {
          console.log(res);
          if (res.code == 200) {
            _this.$message.success("创建成功", function() {
              _this.$router.push({ path: "/index/auth/role/roleList" });
            });
          } else {
            _this.$message.error(res.msg);
          }
        });
      }
    },
    updateRole() {
      let _this = this;
      // console.log({
      //   dbId: _this.id,
      //   role_name: _this.form.name,
      //   menuIds: JSON.parse(JSON.stringify(_this.check_ids)),
      //   remark: _this.form.describe,
      //   permission:JSON.stringify(_this.dataPermissions)
      // });
      if (this.checkPermissionIsSelected()) {
        editRole({
          dbId: _this.id,
          role_name: _this.form.name,
          menuIds: JSON.parse(JSON.stringify(_this.check_ids)),
          remark: _this.form.describe,
          permission: JSON.stringify(_this.dataPermissions),
          deptId: _this.form.deptIds
        }).then(res => {
          console.log(res);
          if (res.code == 200) {
            _this.$message.success("更新成功", function() {
              _this.$router.push({ path: "/index/auth/role/roleList" });
            });
          } else {
            _this.$message.error(res.msg);
          }
        });
      }
    }
  }
};
</script>
<style>
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.ant-checkbox-group-item {
  margin-top: 10px !important;
}
</style>
<style scoped lang="less">
.role-auth {
  display: flex;
  .role-authone {
    min-width: 160px;
    width: 250px;
    height: 400px;
    margin-right: 20px;
    .role-authone-title {
      width: 100%;
      height: 46px;
      background-color: rgba(67, 152, 236, 0.6);
      border-radius: 4px 4px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 10px;
      color: #ffffff;
      border-top: none;
    }
    .role-authone-con {
      border: 1px solid #bbbbbb;
      height: 350px;
      overflow-y: scroll;
      box-sizing: border-box;
      div {
        padding: 0 10px;
      }
      .select {
        background-color: rgba(67, 152, 236, 0.6);
      }
    }
  }
}
</style>
